<template>
	<div>
		<el-form :inline="true" :model="form" class="">
			<el-form-item label="用户名">
				<el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="user_list" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-plus" @click="add" circle></el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="userName" label="用户名" >
				<template slot-scope="scope">
					<el-tag>{{ scope.row.userName }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				prop="isAdmin"
				label="后台管理用户"
				width="120"
			>
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.isAdmin === 0 ? 'danger' : ''">{{ scope.row.isAdmin === 0 ? '否' : '是'}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				prop="status"
				label="状态"
			>
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.status === 0 ? 'danger' : 'success'"  disable-transitions>{{ scope.row.status === 0 ? '停用' : '启用' }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				prop="createTime"
				label="创建时间"
				width="170">
			</el-table-column>
			<el-table-column
				prop="loginTime"
				label="最后登录时间"
				width="170">
			</el-table-column>
			<el-table-column
				prop="modifyTime"
				label="最后修改时间"
				width="170">
			</el-table-column>
			<el-table-column
				prop="remark"
				label="备注信息"
				width="170">
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
			    <template slot-scope="scope">
					<el-popconfirm
						title="是否要删除？"
						icon-color="red"
						@confirm="delete_user( scope.row.userName )"
						style="margin-right: 5px;"
					>	
						<el-button slot="reference" size="mini" type="danger" icon="el-icon-delete">删除</el-button>
					</el-popconfirm>
					<el-button size="mini" icon="el-icon-edit" type="primary" @click=" edit( scope.row ) " v-if=" scope.row.isAdmin == 1">编辑</el-button>
					
			    </template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<edit ref="edit" :type="edit_type" @success="edit_success" />
	</div>
</template>

<script>
	import pagination from '@/components/pagination.vue';
	import edit from './components/edit.vue';
	export default{
		data(){
			return {
				loading:false,
				list : [],
				form: {
					userName: "",
					pageSize : 10,
					startPage : 1,
					isAdmin:1
				},
				total : 0,
				edit_type : 0,
			}
		},
		created(){
			this.user_list();
		},
		methods:{
			add(){
				this.$refs.edit.type = 0;
				this.$refs.edit.open();
			},
			edit( data ){
				this.$refs.edit.type = 1;
				this.$refs.edit.open( data );
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.user_list();
			},
			changePage(e) {
				this.form.startPage = e;
				this.user_list();
			},
			user_list(){
				this.loading = true;
				this.$api.user_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			delete_user( userName ){
				this.$api.user_delete( { userName } ).then( res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if( res.code == 200 ){
						if( this.list.length == 1 && this.form.startPage > 1 ){
							this.form.startPage = this.form.startPage - 1;
						}
						this.user_list();
					}
				});
			},
			edit_success( e ){
				this.user_list();
			},
		},
		components:{
			pagination,edit,
		}
	}
</script>
